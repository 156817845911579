<template>
    <div class="page-content">
        <div class="park-form">
            <el-form inline label-position="top" ref="searchForms" style="display: flex;flex-wrap: wrap">
                <!--<el-form-item label="客户电话" prop="username">-->
                <!--<el-input v-model="searchForm.name" style="width: 220px;" clearable-->
                <!--placeholder="请填写电话"></el-input>-->
                <!--</el-form-item>-->
                <!--<el-form-item style="margin-top: auto;margin-right: auto;">-->
                <!--<div class="fill-btn" @click="reserveFull">查询</div>-->
                <!--</el-form-item>-->
                <el-form-item style="margin-top: auto;margin-left: 20px;margin-right: 0;">
                    <div class="fill-btn" @click="handShowAdd">新增</div>
                </el-form-item>

            </el-form>
        </div>
        <div class="table-content">
            <div class="table-top">
                <div class="table-top-text">
                    <!--已选择{{selectData.length}}项-->
                </div>
            </div>
            <el-table :data="tableData" stripe
                      v-loading="loading"
                      @selection-change="handleSelectionChange"
                      cell-class-name="text-center"
                      header-cell-class-name="header-row-class-name">
                <el-table-column prop="title" label="主题名称" min-width="120px"></el-table-column>
                <el-table-column prop="images" label="主题封面" min-width="50%">
                    <!-- 图片的显示 -->
                    <template slot-scope="scope">
                        <img :src="scope.row.images" min-width="80" height="70" style="width: 100%"/>
                    </template>
                </el-table-column>
                <el-table-column prop="date" label="日期" min-width="120px"></el-table-column>
                <el-table-column prop="start_time" label="开始时间" min-width="120px"></el-table-column>
                <el-table-column prop="end_time" min-width="160px" label="结束时间"></el-table-column>
                <el-table-column width="200" label="操作" fixed="right">
                    <template slot-scope="{row}">
                        <div class="btn-content">
                            <!--<el-button class="el-icon-edit-outline cur-p" type="primary" size="mini"-->
                            <!--@click="handEditShow(row)"></el-button>-->
                            <el-button class="el-icon-delete cur-p" type="primary" size="mini" v-if="row.status != 2"
                                       @click="delType(row)"></el-button>

                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <div class="pagination-content">
                <el-pagination
                        background
                        :current-page.sync="searchForm.page"
                        :page-size="searchForm.limit"
                        @current-change="searchForm.page=$event"
                        @size-change="handleSizeChange"
                        layout="total, prev, pager, next, jumper"
                        :total="total">
                </el-pagination>
            </div>
        </div>

        <el-dialog title="新增设置" :visible.sync="showAdd">
            <div style="padding: 20px;">
                <el-form ref="form" :model="addTeacher" label-width="180px">
                    <el-form-item label="主题">
                        <el-select v-model="addTeacher.themes_id"   clearable="" @change="getThemeTime(addTeacher.themes_id)" placeholder="请选择">
                            <el-option
                                    v-for="item in themeList"
                                    :key="item.id"
                                    :label="item.title"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="日期">

                        <el-date-picker
                                v-model="addTeacher.date"
                                format="yyyy-MM-dd"
                                value-format="yyyy-MM-dd"
                                type="date"
                                placeholder="选择日期">
                        </el-date-picker>

                    </el-form-item>
                    <el-form-item label="主题场次">
                        <el-select v-model="addTeacher.reserve_id"   clearable="" placeholder="请选择">
                            <el-option
                                    v-for="item in timeList"
                                    :key="item.id"
                                    :label="item.start_time+'-'+item.end_time"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="addType" style="margin: 10px auto">保存</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </el-dialog>
        <el-dialog title="编辑" :visible.sync="showEdit">
            <div style="padding: 20px;">
                <el-form ref="form" :model="editForm" label-width="80px">
                    <el-form-item label="主题">
                        <el-select v-model="editForm.themes_id" multiple placeholder="请选择">
                            <el-option
                                    v-for="item in themeList"
                                    :key="item.id"
                                    :label="item.title"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="日期">
                        <el-select v-model="editForm.date" multiple placeholder="请选择">
                            <el-date-picker
                                    v-model="value1"
                                    type="date"
                                    placeholder="选择日期">
                            </el-date-picker>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="主题场次">
                        <el-select v-model="editForm.themes_id" multiple placeholder="请选择">
                            <el-option
                                    v-for="item in timeList"
                                    :key="item.id"
                                    :label="item.start_time+'-'+item.end_time"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="saveType" style="margin: 10px auto">保存</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </el-dialog>

    </div>
</template>

<script>
    import {delToast, saveToast, formVerify, ascertain} from './../../utlis/decorators'
    import RichText from './../../components/RichText'

    export default {
        name: "tenant_account",
        props: {
            parkId: {
                type: [Number, String],
                default: null
            },
        },
        components: {
            RichText
        },
        data() {
            return {
                dialogFormVisible: false,
                loading: false,
                currentTop: 0,
                total: 0,
                showAdd: false,
                showEdit: false,
                showEditPsd: false,
                editing: false,
                orderexcel: false,
                themeList: [],
                searchForm: {
                    page: 1,
                    limit: 10,
                    username: ''
                },
                form: {
                    nickname: '',
                    is_limit: 2
                },
                editForm: {
                    id: [],
                    price_time: '',
                    type: '',
                    end_time: '',
                    store_id: '',
                    themes_id: [],
                    price: ''
                },
                excelorder: {},
                currentId: '',
                tableData: [],
                typeList: [],
                product_id: '',
                addTeacher: {
                    time: '',
                    themes_id: '',
                },
                timeList:[],
                ratio: [{
                    value: '1',
                    label: '百分比'
                }, {
                    value: '2',
                    label: '固定金额'
                }],
                all: [],
                file: null,
            }
        },

        created() {

            this.reserveFull();
            this.getThemeLists();
        },
        computed: {
            getRules() {
                return {
                    role_ids: [{required: true, message: `角色不能为空`, trigger: ['blur', 'change']}],
                    nickname: [{required: true, message: `昵称不能为空`, trigger: ['blur', 'change']}],

                }
            }
        },
        watch: {
            'searchForm.page'() {
                this.reserveFull();
            },

        },

        methods: {
            addDomain() {
                this.addTeacher.price_time.push({
                    value: []
                });


            },
            //头像
            handleAvatarSuccess(res, file) {

                this.editForm.head = res.url;
                this.addTeacher.head = res.url;
                console.log(this.addTeacher.head);
            }
            ,
            getFile(e) {

                this.file = e.target.files[0];


            }
            ,

            //导出
            async userExport() {
                let a = document.createElement('a');
                a.href = 'http://www.urxms.com/order.xls';
                a.tartget = '_blank';
                a.click();
                // this.$downFile('userExport',this.searchForm,'用户模板');
            }
            ,
            beforeAvatarUpload(file) {
                const isJPG = file.type === 'image/png';
                const isLt2M = file.size / 1024 / 1024 < 2;

                if (!isJPG) {
                    this.$message.error('上传头像图片只能是 JPG,png 格式!');
                }
                if (!isLt2M) {
                    this.$message.error('上传头像图片大小不能超过 2MB!');
                }

            }
            ,
            handShowAdd() {


                this.showAdd = true;
            }
            ,
            async getShowExcel() {

                this.orderexcel = true;


            }
            ,
            //导入
            async excelCreate() {
                let form = new FormData();
                form.append('excels', this.file)
                for (let userExcelKey in this.excelorder) {
                    form.append(userExcelKey, this.excelorder[userExcelKey])
                }
                let [e, data] = await this.$api.orderExcel(form);
                if (e) return;
                if (data.code == 200) {
                    this.orderexcel = false;
                    this.reserveFull();
                    this.$message.success(data.msg);

                }

            }

            ,
            //获取主题
            async getThemeLists() {


                let [e, data] = await this.$api.storeThemeList();
                if (e) return;

                if (data.code === 200) {
                    this.themeList = data.data;
                    // this.themeList.push({'id':0,'title':'请选择主题'});
                }
            },
            //主题对应的场次
            async getThemeTime(themes_id) {


                let [e, data] = await this.$api.getThemesTime({themes_id:themes_id});
                if (e) return;

                if (data.code === 200) {
                    this.timeList = data.data;
                }
            },

            handEditShow(row) {


                this.editForm = row;

                this.showEdit = true;
            }
            ,
            //页面数量改变
            handleSizeChange(val) {
                this.searchForm.page = 1;
                this.searchForm.limit = val;
            }
            ,
            handleSelectionChange(val) {
                this.selectData = val;
            }
            ,
            //获取列表
            async reserveFull() {

                let [e, data] = await this.$api.reserveFull(this.searchForm);
                if (e) return;
                if (data.code === 200) {
                    this.tableData = data.data.data;
                    this.total = data.data.total;
                }
            },

            @delToast()
            async delType({id}) {
                let [e, data] = await this.$api.reserveFullDel({id});
                if (e) return;
                if (data.code === 200) {
                    this.reserveFull();
                    this.$message.success('删除成功')
                } else {
                    this.$message.warning(`${data.msg}`)
                }
            }
            ,

            //添加
            async addType() {
                this.loading = true;
                this.addTeacher.product_id = this.product_id;
                let [e, data] = await this.$api.reserveFullCreate(this.addTeacher);
                this.loading = false;
                if (e) return;

                if (data.code === 200) {
                    // this.getTeacherList();
                    this.loading = false;
                    this.showAdd = false;
                    this.reserveFull();
                    this.$message.success('添加成功')
                } else {

                    this.$message.warning(`${data.msg}`)

                }
            }
            ,
            //类型编辑
            async saveType() {
                this.editing = true;
                let [e, data] = await this.$api.reserveSave(this.editForm);
                this.editing = false;
                if (e) return;
                if (data.code === 200) {
                    this.showEdit = false;
                    this.reserveFull();
                    this.$message.success('保存成功')
                } else {
                    this.$message.warning(`${data.msg}`)
                }
            }
        }
        ,
    }
</script>
<style scoped lang="less">
    .page-content {
        width: calc(~'100% - 60px');
        margin: 26px 30px;
        box-sizing: border-box;
        background-color: #fff;
        border-radius: 4px;
        padding: 20px 30px;

        .park-form {
            border-bottom: 1px solid #efefef;
        }

        .table-content {
            padding-top: 20px;

            .table-top {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .table-top-text {
                    font-size: 16px;
                    font-weight: 400;
                    color: rgba(35, 39, 52, 1);
                }

                .table-top-btn {
                }
            }
        }
    }

    .btn-content {
        display: flex;
        justify-content: center;
        align-items: center;

        .iconfont {
            font-size: 20px;
            color: rgb(190, 199, 213);
            margin: 0 10px;
            cursor: pointer;
        }

        .icon-shanchu {
            font-size: 24px;
        }
    }

    .box-shadow {
        width: calc(~'100% + 40px');
        height: 50px;
        position: absolute;
        top: 0;
        left: -20px;
        z-index: 10;
        box-shadow: 0 0 4px #b4b4b4;
    }

    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }

    .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }

    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
    }

    .avatar {
        width: 178px;
        height: 178px;
        display: block;
    }
</style>
